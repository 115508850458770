.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #d9dee3;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
  margin: auto 30%;
}

@media screen and (max-width: 458px) {
  .card {
    margin: auto;
  }
}
