#innerDisplay {
  margin: auto 20%;
}
#motherDisplay {
  margin: 10% auto;
}

@media screen and (max-width: 480px) {
  #innerDisplay {
    margin: auto 0;
  }
  #motherDisplay {
    margin: auto;
  }
}
@media screen and (min-width: 481px) and (max-width: 1040px) {
  #motherDisplay {
    margin: 5% auto;
  }
}
